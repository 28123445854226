html, head, body, #root, #container{
    height: 100%;
}
#root{
    padding: 8px;
    box-sizing: border-box;
    height: 100%;
    background: #303030;
}
body {
  margin: 0;
  padding: 0;
  
}
#container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
}
#inner-container {
    display: -webkit-flex;
    display: flex;
}
#family-tree{
    background: orange;
    width: 100%;
    min-height: 50px;
}
#sidebar{
    background: red;
    width: 100%;
    min-height: 50px;
}
#footer{
    background: yellow;
    width: 100%;
    min-height: 50px;
}
